<!-- 勘探安装数据 -->
<template>
  <div class="expoleManageBox bgblue">
    <div class="top"></div>
    <div class="form">
      <el-form
        class="demo-form-inline"
        style="position: relative"
        label-width="100px"
      >
        <el-row>
          <el-col :span="4"
            ><el-form-item label="用户名称:">
              <el-input
                v-model="formInline.memberName"
                clearable
                placeholder="请输入用户名称"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="4"
            ><el-form-item label="证件类型:">
              <el-select
                v-model="formInline.certificateType"
                placeholder="请选择证件类型"
                style="width: 100%"
                clearable
              >
                <el-option
                  v-for="item in roofType"
                  :key="item.region"
                  :label="item.label"
                  :value="item.region"
                >
                </el-option>
              </el-select> </el-form-item
          ></el-col>
          <el-col :span="4"
            ><el-form-item label="证件号:">
              <el-input
                clearable
                v-model="formInline.certificateNo"
                placeholder="请输入证件号"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="4">
            <el-form-item label="投保人手机号:">
              <el-input
                clearable
                v-model="formInline.phone"
                placeholder="请输入手机号:"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="4">
            <el-form-item label="工号:">
              <el-input
                clearable
                v-model="formInline.jobNo"
                placeholder="请输入工号"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="4"
            ><el-form-item label="代理商编号:">
              <el-input
                clearable
                v-model="formInline.agentCode"
                placeholder="请输入代理商编号"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="4"
            ><el-form-item label="代理商名称:">
              <el-input
                clearable
                v-model="formInline.agentName"
                placeholder="请输入代理商名称"
              ></el-input> </el-form-item
          ></el-col>
          <div v-if="advanced">
            <el-col :span="4">
              <el-form-item label="业务员:">
                <el-input
                  clearable
                  v-model="formInline.salesmanName"
                  placeholder="请输入业务员"
                ></el-input> </el-form-item
            ></el-col>
            <el-col :span="4">
              <el-form-item label="投保状态:">
                <el-select
                  v-model="formInline.status"
                  placeholder="请选择投保状态"
                  style="width: 100%"
                  clearable
                >
                  <el-option
                    v-for="item in explorationStatus"
                    :key="item.region"
                    :label="item.label"
                    :value="item.region"
                  >
                  </el-option>
                </el-select> </el-form-item
            ></el-col>
            <el-col :span="4"
              ><el-form-item label="所属分公司:">
                <el-input
                  clearable
                  v-model="formInline.companyName"
                  placeholder="请输入所属分公司"
                ></el-input> </el-form-item
            ></el-col>
            <el-col :span="9">
              <el-form-item label="按勘探审批时间:">
                <el-date-picker
                  style="width: 95%"
                  v-model="value1"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </div>
          <el-col :span="4">
            <div class="pt5">
              <el-button type="text" @click="toggleAdvanced"
                ><i
                  :class="advanced ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
                ></i
                >{{ advanced ? "收起" : "展开" }}</el-button
              >
              <el-button type="primary">
                <i class="el-icon-search"></i>查询</el-button
              >
              <el-button plain> <i class="el-icon-refresh"></i>重置</el-button>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <div class="bgwhite">
      <el-row>
        <el-button type="primary" plain @click="open()" >
          <i class="el-icon-delete"></i>&nbsp;&nbsp;作废</el-button
        >
        <el-button
          type="primary"
          plain
          @click="dialogVisible = true"
         
        >
          <i class="el-icon-plus"></i>&nbsp;&nbsp;新增</el-button
        >
      </el-row>
      <el-table
        :data="tableData"
        style="width: 100%"
        border
        height="calc(100vh - 300px)"
      >
        <el-table-column type="selection"> </el-table-column>
        <el-table-column prop=" " label="操作" align="center" fixed="left">
          <el-button type="text" class="btn-blue active">查看</el-button>
        </el-table-column>

        <el-table-column
          prop="loginName"
          label="账号"
          align="center"
        ></el-table-column>
        <el-table-column prop="memberName" label="用户名称" align="center">
        </el-table-column>
        <el-table-column prop="certificateType" label="证件类型" align="center">
        </el-table-column>
        <el-table-column prop="certificateNo" label="证件号" align="center">
          <template slot-scope="scope">
            <span style="color: #409eff">{{ scope.row.exploeNum }}</span>
          </template>
        </el-table-column>
        <el-table-column prop=" phone" label="投保人手机" align="center">
          <template slot-scope="scope">
            <span style="color: #409eff">{{ scope.row.exploeNum }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="document" label="保险文件" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              style="color: #ffeaaa"
              @click="dialogVisiblelook = true"
              >{{ scope.row.document }}</el-button
            >
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" width="80" align="center">
          <el-button type="text" class="btn-green active">正常</el-button>
        </el-table-column>
        <el-table-column
          prop="agentCode"
          label="代理商编号"
          width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column prop=" agentName" label="代理商名称" align="center">
        </el-table-column>
        <el-table-column prop="salesmanName" label="业务员" align="center">
        </el-table-column>
        <el-table-column prop="companyName" label="所属分公司" align="center">
        </el-table-column>
        <el-table-column prop="updateName" label="更新人" align="center">
        </el-table-column>
        <el-table-column prop=" updateDate" label="更新时间" align="center">
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage4"
          :page-sizes="[100, 200, 300, 400]"
          :page-size="100"
          layout="total, sizes, prev, pager, next, jumper"
          :total="400"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="新增"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      width="60%"
    >

      <div>
        <div style="font-size: 15px; margin-top: 20px; margin-bottom: 10px; font-weight: 600;">
            用户信息
          </div>
        <el-form
          :model="form"
          
          class="demo-form-inline"
          style="position: relative"
          label-width="100px"
        >
          <el-row>
            <el-col :span="6"
              ><el-form-item label="账号:">
                <el-input
                  v-model="form.user"
                  placeholder="请输入账号"
                ></el-input> </el-form-item
            ></el-col>
            <el-col :span="6"
              ><el-form-item label="用户名称:">
                <el-input
                  v-model="formInline.user"
                  placeholder="请输入用户名称"
                ></el-input> </el-form-item
            ></el-col>
            <el-col :span="6"
              ><el-form-item label="手机号:">
                <el-input
                  v-model="formInline.user"
                  placeholder="请输入手机号"
                ></el-input> </el-form-item
            ></el-col>
            <el-col :span="6"
              ><el-form-item label="证件类型:">
                <el-select
               
                  v-model="formInline.region"
                  placeholder="请选择类型"
                  style="width:  100%"
                >
                  <el-option
                    v-for="item in roofType"
                    :key="item.region"
                    :label="item.label"
                    :value="item.region"
                  >
                  </el-option>
                </el-select> </el-form-item
            ></el-col>
          
   
            <el-col :span="6">
              <el-form-item label="证件号:">
                <el-input
                  v-model="formInline.user"
                  placeholder="请输入证件号"
                ></el-input> </el-form-item
            ></el-col>
            <el-col :span="6">
              <el-form-item label="代理商编号:">
                <el-input
                  v-model="formInline.user"
                  placeholder="请输入编号"
                ></el-input> </el-form-item
            ></el-col>
            <el-col :span="6"
              ><el-form-item label="代理商名称:">
                <el-input
                  v-model="formInline.user"
                  placeholder="请输入名称"
                ></el-input> </el-form-item
            ></el-col>
            <el-col :span="6">
              <el-form-item label="业务员:">
                <el-input
                  v-model="formInline.user"
                  placeholder="请输入业务员"
                ></el-input> </el-form-item
            ></el-col>
       
     
            <el-col :span="6
            ">
              <el-form-item label="所属分公司:">
                <el-input
                  v-model="formInline.user"
                  placeholder="请输入分公司"
                ></el-input> </el-form-item
            ></el-col>
          
          </el-row>
          <div style="font-size: 15px; margin-top: 10px; margin-bottom: 10px; font-weight: 600;">
            保险信息
          </div>
          <el-row>
            <el-col :span="7">
              <el-form-item label="投保日期:">
                <el-date-picker
                  v-model="value2"
                  type="date"
                  placeholder="选择日期"
                  style="width: 100%;"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="9">
              <el-form-item label="保险日期:">
                <el-date-picker
                  v-model="value1"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  style="width: 100%;"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item label="保险文件:">
                <el-upload
                  action="https://jsonplaceholder.typicode.com/posts/"
                  list-type="picture-card"
                  :on-preview="handlePictureCardPreview"
                  :on-remove="handleRemove"
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog :visible.sync="dialogVisibles">
                  <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
              </el-form-item>
            </el-col>

          

        
          </el-row>
        </el-form>
        <el-row>
        <el-col :span="6" style="color: red; text-align: center;" >定时保险到期提醒：提前15天提醒</el-col>
      </el-row>
        <el-row type="flex" justify="center">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisible = false"
            >确 定</el-button
          >
        </el-row>
      </div>
    </el-dialog>
    <el-dialog
      title="保险文件"
      :visible.sync="dialogVisiblelook"
      width="30%"
      :before-close="handleClose"
    >
      <div class="pdf">
        <div class="box"></div>
        <div>张三保险文档</div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisiblelook = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisiblelook = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
  
  <script>
export default {
  created() {
    this.getdata();
  },
  data() {
    return {
      queryInfo: {
        pageSize: 100,
        pageCount: 1,
        currPage: 1,
        condition: {},
      },
      tableData: [],
      advanced: false,
      formInline: {
        memberName: "",
        certificateType: "",
        certificateNo: "",
        phone: "",
        jobNo: "",
        agentCode: "",
        agentName: "",
        salesmanName: "",
        status: "",
        companyName: "",
      },
      form: {
        memberName: "",
        certificateType: "",
        certificateNo: "",
        phone: "",
        jobNo: "",
        agentCode: "",
        agentName: "",
        salesmanName: "",
        status: "",
        companyName: "",
      },
      digdata: {},
      roofType: [
        {
          region: "选项1",
          label: "二代身份证",
        },
      ],
      explorationStatus: [
        {
          region: "选项1",
          label: "未投保",
        },
        {
          region: "选项2",
          label: "已投保",
        },
      ],
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      value1: [new Date(2024, 3, 20), new Date(2024, 3, 20)],
      value2: "",
      dialogVisible: false,
      dialogImageUrl: "",
      dialogVisibles: false,
      dialogVisiblelook: false,
    };
  },
  methods: {
    handleClick(row) {
      console.log(row);
    },
    onSubmit() {
      console.log("submit!");
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisibles = true;
    },
    toggleAdvanced() {
      this.advanced = !this.advanced;
    },
    open() {
      this.$alert("是否确认作废", "提示", {
        confirmButtonText: "确定",
        callback: (action) => {
          const li = this.tableData.map((item) => {
            return item.id;
          });
          console.log(li);
          this.$http.post("insure/list", li).then((res) => {
            console.log("pp");
          });
        },
      });
    },
    getdata() {
      this.$http.post("insure/list", this.queryInfo).then((res) => {
        this.tableData = res.data.data.data;
      });
    },
    lokk() {
      this.$http.post("insure/queryList", this.formInline).then((res) => {
        this.tableData = res.data.data;
      });
    },
    star() {
      this.formInline = {
        memberName: "",
        certificateType: "",
        certificateNo: "",
        phone: "",
        jobNo: "",
        agentCode: "",
        agentName: "",
        salesmanName: "",
        status: "",
        companyName: "",
      };
      this.$http.post("insure/list", this.queryInfo).then((res) => {
        this.tableData = res.data.data.data;
      });
    },
  },
};
</script>
  
  <style lang="less" >
.expoleManageBox {
  .el-form-item__label{
    font-size: 13px;
  }

  .el-table td {
      padding: 0.5px 0px;
      box-sizing: border-box;
    }
    .el-table .cell{
      line-height: 15px;
    }
  margin: 0;
  padding: 10px 10px;
  margin-right: 10px;
  .el-col-5 {
    width: 23%;
  }
  .el-col-4 {
    width: 19.5% !important;
  }
  .el-col-7{
    width: 32%;
  }
  .el-dialog__header {
    padding: 5px 10px 0px 10px;

    font-size: 15px;
    box-sizing: border-box;
  }
  .el-button {
    padding: 7px 10px !important;
  }
  .el-date-editor .el-range__icon {
    line-height: 21px !important;
  }
  .el-dialog__body {
    padding: 10px;
  }
  .el-table {
    margin-top: 10px !important;
  }
  .form {
    background-color: #fff;
    padding-top: 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px #ccc;
  }
  .pagination {
    float: right;
  }
  .bgwhite {
    margin-top: 10px;
    padding: 10px;
    border-radius: 10px;
    overflow: auto;
    background-color: white;
  }
 
  .pdf {
    .box {
      width: 200px;
      // height: 200px;
      border-radius: 5px;
      border: 1px solid #000;
    }
    div {
      width: 200px;
      text-align: center;
    }
  }
  /deep/ .el-form-item__label {
    width: 140px;
    font-size: 11px;
  }

  //   /deep/.el-input--small .el-input__inner {
  //   // width: 140px
  // }
  // /deep/.el-form-item__content {
  //   width: 100px;
  // }
  /deep/.el-table {
    height: calc(100vh - 500px);
  }
  /deep/ .el-table td {
    padding: 4px 0;
  }
  .active {
    padding: 2px 7px !important;
  }
  
  .el-upload--picture-card{
    width: 100px;
    height: 100px;
    line-height: 100px;
  }
}
</style>