import { render, staticRenderFns } from "./inanceList.vue?vue&type=template&id=08ba3f86"
import script from "./inanceList.vue?vue&type=script&lang=js"
export * from "./inanceList.vue?vue&type=script&lang=js"
import style0 from "./inanceList.vue?vue&type=style&index=0&id=08ba3f86&prod&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "inanceList.vue"
export default component.exports